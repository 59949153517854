import { FormControl, FormLabel, RadioGroup } from "@mui/material"
import { Fragment } from "react"
import { handleDateTimeDefault } from "../../../helpers/utils"

export const CardContent = ({ cardData, prePrint }) => {

    console.log(cardData, "cardData");

    return (
        <Fragment>
            <div className="main">
                <div className="card">
                    <div className="header" style={{ opacity: prePrint ? 0 : 1 }}>
                        <img src="/assets/images/logo-icon.png" alt="logo" width="70" />
                        <h1>ANUSHAKTINAGAR SPORTS MANAGEMENT COMMITTEE (ASMC)</h1>
                    </div>
                    <div className="profile">
                        <h2 style={{ opacity: prePrint ? 0 : 1 }}>Member Profile</h2>
                        <div className="profile-details">
                            <table>
                                <tr>
                                    <td style={{ width: "260px", height: "10rem", opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }} >Name</td>
                                    <td style={{ height: "10rem", lineBreak: "anywhere", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "260px", opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Mobile No.</td>
                                    <td style={{ lineBreak: "anywhere", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.mobile}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "260px", opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Email ID</td>
                                    <td style={{ lineBreak: "anywhere", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.email}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "260px", opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>CHSS / ID</td>
                                    <td style={{ lineBreak: "anywhere", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.chss_number}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "260px", opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>DOB</td>
                                    <td style={{ lineBreak: "anywhere", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.dob ? handleDateTimeDefault(cardData?.dob, "dd/MM/yyyy") : "-"}</td>
                                </tr>
                            </table>
                            <div className="profile-photo" style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>
                                <div className="photo"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="membership-details">
                    <h2 style={{ opacity: prePrint ? 0 : 1 }}>Membership Details</h2>
                    <div className="membership-table">
                        <table>
                            <tr>
                                <td style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Membership No.</td>
                                <td style={{ minWidth: "700px", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.membership_no}</td>
                            </tr>
                            <tr>
                                <td style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Membership Type</td>
                                <td style={{ minWidth: "700px", border: prePrint ? "none" : "2px solid #4e889b" }}>
                                    {cardData?.membership_type}
                                    {" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>{cardData?.chss_number === "NON CHSS" ? "(Non Dependent)" : null}</span>
                                    {cardData?.membership_type === "Secondary" && <span style={{ color: "red", fontWeight: "bold" }}>{cardData?.is_dependent ? null : "(Non Dependent)"}</span>}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Membership Plan</td>
                                <td style={{ minWidth: "700px", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.membership_plan}</td>
                            </tr>
                            <tr>
                                <td style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Relation With Primary</td>
                                <td style={{ minWidth: "700px", border: prePrint ? "none" : "2px solid #4e889b" }}>{cardData?.relation_with_primary}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="footer" style={{ opacity: prePrint ? 0 : 1 }}>
                    Office: NCC Complex, Opposite Post Office, Anushaktinagar<br />
                    Email: asmc.dae@gmail.com; Phone: 2558 0497
                </div>
            </div>
            <div className="back-main">
                <div class="table-container">
                    <table>
                        <tr style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }} >
                            <th style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Sr. No.</th>
                            <th style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Sports Activities</th>
                            <th style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Category Main / Sub</th>
                            <th style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Valid Upto</th>
                            <th style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Batch Name</th>
                            <th style={{ opacity: prePrint ? 0 : 1, border: prePrint ? "none" : "2px solid #4e889b" }}>Signature</th>
                        </tr>
                        {
                            cardData?.bookings && cardData?.bookings.map((obj, index) => {
                                return (
                                    <tr>
                                        <td style={{ border: prePrint ? "none" : "2px solid #4e889b" }}>{index + 1}</td>
                                        <td style={{ border: prePrint ? "none" : "2px solid #4e889b" }}>
                                            <span>{obj?.activity}</span>
                                            <span style={{ display: "block", fontSize: 35 }}>{obj?.location ? `${obj?.location}` : ""} {obj?.sublocation ? `(${obj?.sublocation})` : ""}</span>
                                        </td>
                                        <td style={{ border: prePrint ? "none" : "2px solid #4e889b" }}>
                                            <span>{obj?.category}</span>
                                            <span style={{ display: "block", fontSize: 35 }}>{obj?.sub_category ? `(${obj?.sub_category})` : ""}</span>
                                        </td>
                                        <td style={{ border: prePrint ? "none" : "2px solid #4e889b" }}>{obj?.valid_upto}</td>
                                        <td style={{ border: prePrint ? "none" : "2px solid #4e889b" }}>{obj?.batch_name}</td>
                                        <td style={{ border: prePrint ? "none" : "2px solid #4e889b" }}></td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
            </div>
        </Fragment>
    )
}