import React, { Fragment, useState } from 'react';
import { Grid } from '@mui/material';
import { formatISO, parseISO, isBefore, isAfter } from 'date-fns';
import DatePickerComponent from './DatePicker';
import TimePickerComponent from './TimePicker';

const DateTimePickerComponent = ({
    values,
    setFieldValue,
    handleBlur,
    disabled,
    errors: formError = {}
}) => {
    const [errors, setErrors] = useState(formError || {});

    const validateDates = (start_date, end_date) => {
        const errors = {};
        if (start_date && isBefore(start_date, new Date())) {
            errors.start_date = 'Start date cannot be in the past.';
        }
        if (start_date && end_date && isBefore(end_date, start_date)) {
            errors.end_date = 'End date must be after the start date.';
        }
        setErrors(errors);
        // return true or false
        return Object.keys(errors).length === 0;
    };

    const handleStartDateChange = (val) => {
        const newStartDate = formatISO(val);
        const errorExist = validateDates(val, values?.end_date ? parseISO(values.end_date) : null);
        setFieldValue('start_date', newStartDate);
    };

    const handleEndDateChange = (val) => {
        const newEndDate = formatISO(val);
        const errorExist = validateDates(values?.start_date ? parseISO(values.start_date) : null, val);
        setFieldValue('end_date', newEndDate);
    };


    return (
        <Fragment>
            <Grid item xs={12} md={6}>
                <DatePickerComponent
                    id="start_date"
                    name="start_date"
                    label="Start Date *"
                    onChange={handleStartDateChange}
                    value={values?.start_date ? new Date(parseISO(values?.start_date)) : null}
                    fullWidth
                    onBlur={handleBlur}
                    variant="outlined"
                    disabled={disabled}
                    error={!!errors.start_date}
                    helperText={errors.start_date}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePickerComponent
                    id="end_date"
                    name="end_date"
                    label="End Date *"
                    onChange={handleEndDateChange}
                    value={values?.end_date ? new Date(parseISO(values?.end_date)) : null}
                    fullWidth
                    onBlur={handleBlur}
                    variant="outlined"
                    disabled={disabled}
                    error={!!errors.end_date}
                    helperText={errors.end_date}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <TimePickerComponent
                    label="Start Time *"
                    onChange={(e) => setFieldValue('start_time', formatISO(e))}
                    value={values?.start_time ? new Date(parseISO(values?.start_time)) : null}
                    name="start_time"
                    keyname="Time"
                    id="time-select-1"
                    sx={{ width: '100%' }}
                    size="small"
                    variant="outlined"
                    disabled={disabled}
                    error={!!errors.start_time}
                    helperText={errors.start_time}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <TimePickerComponent
                    label="End Time *"
                    onChange={(e) => setFieldValue('end_time', formatISO(e))}
                    value={values?.end_time ? new Date(parseISO(values?.end_time)) : null}
                    name="end_time"
                    keyname="Time"
                    id="time-select-2"
                    sx={{ width: '100%' }}
                    size="small"
                    variant="outlined"
                    disabled={disabled}
                    error={!!errors.end_time}
                    helperText={errors.end_time}
                />
            </Grid>
        </Fragment>
    );
};

export default DateTimePickerComponent;
