import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { hallsListParser } from './hallsParser';

const hallsApis = createApi({
    reducerPath: 'hallsApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("asmc_token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Hall"],
    endpoints: build => ({
        getHallList: build.query({
            query: (params) => ({
                url: "/halls/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => hallsListParser(response),
            providesTags: ['Hall'],
        }),
        addNewHall: build.mutation({
            query: (payload) => ({
                url: "/halls",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Hall'],
        }),
        updateHall: build.mutation({
            query: (payload) => ({
                url: "/halls",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Hall'],
        }),
        updateStatus: build.mutation({
            query: (payload) => ({
                url: "/halls/status",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Hall'],
        }),
        deleteHall: build.mutation({
            query: (params) => ({
                url: "/halls",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Hall'],
        }),
    }),
});

export const { useGetHallListQuery, useAddNewHallMutation, useUpdateHallMutation, useUpdateStatusMutation, useDeleteHallMutation } = hallsApis;
export default hallsApis;