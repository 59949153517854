import { CloseOutlined, DownloadOutlined } from "@mui/icons-material";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import { format, isValid, parseISO } from "date-fns";
import { toPng } from "html-to-image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { CardContent } from "./CardContent";
import { useGetSingleMembersQuery } from "../../../store/members/membersApis";
// import "./member-card.css";

export const MemberCard = ({ data, show, close }) => {
    const { data: memberData } = useGetSingleMembersQuery({ _id: data?._id }, { skip: !data?._id });
    // const [memberData, setMemberData] = useState();
    const [cardData, setCardData] = useState(null);
    const [prePrint, setPrePrint] = useState(false);
    const ref = useRef(null);

    const handleCardGenerate = (type) => {
        if (type === "primary") {
            let payload = {
                name: data?.name,
                mobile: data?.mobile,
                email: data?.email,
                chss_number: data?.chss_number,
                dob: data?.dob,

                membership_no: `P-${data?.member_id}`,
                membership_type: "Primary",
                membership_plan: data?.current_plan?.plan_name,
                relation_with_primary: "Self",
            };

            let bookingData = [];
            memberData?.bookings && memberData?.bookings.map((obj, index) => {
                if (obj?.primary_eligible) {
                    const [day, month, year] = obj?.fees_breakup?.end_date.split('/').map(Number);
                    const expirePlanDate = new Date(year, month - 1, day);
                    bookingData.push({
                        id: index + 1,
                        activity: obj?.activity_id?.name,
                        location: obj?.batch?.location_id?.title,
                        sublocation: obj?.batch?.sublocation_id?.title,
                        category: obj?.batch?.category_id?.title,
                        sub_category: obj?.batch?.subcategory_name,
                        valid_upto: isValid(expirePlanDate) ? format(expirePlanDate, "MMM, yyyy") : "-",
                        batch_name: obj?.batch?.batch_name,
                        is_non_dependent: obj.chss_number
                    })
                }
            })

            payload.bookings = bookingData;

            setCardData(payload);
        } else {
            let familyData = data?.family_details.find((obj) => obj._id === type);
            let index = data?.family_details.findIndex((obj) => obj._id === type);
            let payload = {
                name: familyData?.name,
                mobile: familyData?.mobile || data?.mobile || "-",
                email: familyData?.email || data?.email || "-",
                chss_number: familyData?.card_number || "-",
                dob: familyData?.dob || "-",
                is_dependent: familyData?.is_dependent,

                membership_no: `S${index + 1}-${data?.member_id}`,
                membership_type: "Secondary",
                membership_plan: data?.current_plan?.plan_name,
                relation_with_primary: familyData?.relation || "-",
            };

            let bookingData = [];
            memberData?.bookings && memberData?.bookings.map((obj, index) => {
                if (!obj?.primary_eligible) {
                    if (obj?.family_member && obj?.family_member.length > 0 && obj?.family_member[0]?._id === familyData?._id) {
                        const [day, month, year] = obj?.fees_breakup?.end_date.split('/').map(Number);
                        const expirePlanDate = new Date(year, month - 1, day);
                        bookingData.push({
                            id: index + 1,
                            activity: obj?.activity_id?.name,
                            location: obj?.batch?.location_id?.title,
                            sublocation: obj?.batch?.sublocation_id?.title,
                            category: obj?.batch?.category_id?.title,
                            sub_category: obj?.batch?.subcategory_name,
                            valid_upto: isValid(expirePlanDate) ? format(expirePlanDate, "MMM, yyyy") : "-",
                            batch_name: obj?.batch?.batch_name
                        })
                    }
                }
            })

            payload.bookings = bookingData;

            setCardData(payload);
        }
    }


    const onButtonClick = useCallback(() => {
        // setGenerateCard(data);
        // return false;
        if (ref.current === null) {
            return
        }

        console.log(cardData, "cardData");

        toPng(ref.current, { width: 2495, height: 1748 })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = cardData?.membership_no + '-card.png'
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
    }, [ref, cardData])

    return (
        <Dialog
            open={show}
            onClose={() => {
                setCardData(null);
                close();
            }}
            maxWidth="sm"
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" style={{ display: "flex", justifyContent: "space-between" }}>
                    Member Card
                </Typography>
                <IconButton onClick={() => [close(), setCardData(null)]}>
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                        Generate Card For
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={(e) => handleCardGenerate(e.target.value)}
                    >
                        <FormControlLabel value={"primary"} control={<Radio />} label={`${data?.name} (Primary) P-${data?.member_id}`} />
                        {
                            data?.family_details?.map((item, index) => (
                                <FormControlLabel value={`${item?._id}`} control={<Radio />} label={`${item?.name} (Secondary) S${index + 1}-${data?.member_id}`} />
                            ))
                        }
                    </RadioGroup>
                    <FormLabel id="demo-radio-buttons-group-label">
                        Printing Type
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="normal"
                        name="radio-buttons-group"
                        onChange={(e) => setPrePrint(e.target.value === "preprint")}
                    >
                        <FormControlLabel value={"normal"} control={<Radio />} label={`Normal Print`} />
                        <FormControlLabel value={"preprint"} control={<Radio />} label={`Pre-Print`} />
                    </RadioGroup>
                </FormControl>
                <div className="card-wrapper-container">
                    <div className="card-wrapper">
                        <CardContent cardData={cardData} prePrint={prePrint} />
                    </div>
                </div>
                <div style={{ opacity: 0, height: 0, overflow: "hidden" }}>
                    <div ref={ref} className="card-wrapper">
                        <CardContent cardData={cardData} prePrint={prePrint} />
                    </div>
                </div>
            </DialogContent>
            {
                cardData && (
                    <DialogActions>
                        <Button onClick={close}>Close</Button>
                        <Button onClick={onButtonClick}>Download</Button>
                    </DialogActions>
                )
            }
        </Dialog>
    );
}