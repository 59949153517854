import { createSlice } from "@reduxjs/toolkit";

const defaultFormValue = {
}

const initialState = {
    showDrawer: false,
    formType: "",
    initialValues: { ...defaultFormValue },
    pagination: {
        pageNo: 0,
        limit: 10,
        sortBy: -1,
        sortField: "hall_id",
        keywords: ""
    }
};

export const hallsSlice = createSlice({
    name: "halls",
    initialState,
    reducers: {
        changeHallInitialState: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawer = showDrawer;
            if (formType)
                state.formType = formType;
            if (initialValues)
                state.initialValues = initialValues;
            if (!initialValues)
                state.initialValues = defaultFormValue;
        },
        handlePaginationState: (state, action) => {
            const { payload } = action;
            state.pagination = payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { changeHallInitialState, handlePaginationState } = hallsSlice.actions;

export default hallsSlice;
