import * as yup from "yup";

export const EventsValidation = yup.object().shape({
    images: yup.mixed().required("Required"),
    event_name: yup.mixed().required("Required"),
    location_id: yup.mixed().required("Required"),
    description: yup.mixed().required("Required"),
    start_date: yup.mixed().required("Required"),
    end_date: yup.mixed().required("Required"),
    start_time: yup.mixed().required("Required"),
    end_time: yup.mixed().required("Required"),
    event_type: yup.mixed().required("Required"),
    members_type: yup.mixed().required("Required"),
    members_player_limit: yup.mixed().required("Required"),
    non_members_player_limit: yup.mixed().required("Required"),
});